.pool-container {
  border: 2px solid rgba(255, 255, 255, 0.298);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

.pool-title {
  font-size: 14px;
  font-weight: 500;
  color: #a4b0b6;
}

.pool-amount-container {
  display: flex;
  align-items: center;
  margin-top: 12px;
  justify-content: center;
}
.pool-amount {
  color: #f7f7f8;
  /* font-size: 24px; */
  font-weight: 700;
}

.pool-unit {
  color: #f7f7f8;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 4px;
  margin-left: 4px;
}
