.linear-text-color {
  background: var(
    --text-color-text-brand-default,
    linear-gradient(180deg, #1bbff4 0%, #1beca7 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.deposit-modal-container {
  background: transparent !important;
  box-shadow: none !important;
}
.deposit-modal-circle-container {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.deposit-modal-circle-content {
  width: calc(100vw * 1 / 3);
  height: calc(100vw * 1 / 3);
  max-width: 600px;
  max-height: 600px;
  aspect-ratio: 1;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.deposit-modal-box-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.deposit-modal-box {
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0.7);
  background: #122126;
  /* $effect-bgblur */
  backdrop-filter: blur(7.5px);
  padding: 24px;
}
.deposit-modal-box-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
.tab-container {
  background: #0d171b;
  border-radius: 4px;
  margin-top: 24px;
}
.tab-button {
  flex: 1;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
}
.tab-button-active {
  color: #0d171b;
  background: linear-gradient(180deg, #1beca6 0%, #1bbef5 100%);
  border-radius: 4px;
}
.azero-amount {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.azero-unit {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  margin-left: 4px;
}
.deposit-box-amount-input {
  border-radius: 4px;
  background: #0d171b;
  border: "0px";
  align-items: center;
  color: #f7f7f8;
  margin-top: 12px;
  padding: 8px;
  justify-content: center;
}
