.pandora-number-text {
  color: #fff;
  font-size: 64px;
  font-style: bold;
  font-weight: 500;
  line-height: normal;
  text-shadow: 0 0 32px white;
  color: transparent;
}

.section-pandora-left {
  font-family: "Space Grotesk";
  width: 100%;
}

.pandora-effect-icon {
  flex-shrink: 0;
  /* mix-blend-mode: screen; */
  vertical-align: middle;
  max-width: 100%;
  filter: brightness(1.3);
}

.pandora-modal-overlay {
  background-color: #063466;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 12px;
}

.pandora-modal-ticket-overlay {
  background-color: #063466;
  opacity: 0.8;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 12px;
  width: max-content;
}

.pandora-modal-container {
  border-radius: 12px;
  border: 2px solid #00d5c4;
  padding: 20px;
}

.pandora-title-input {
  color: #a4b0b6;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 12px;
}

.pandora-input {
  border: none !important;
  background-color: #122126 !important;
  text-align: center;
  color: white;
}

.pandora-amount-box-content {
  background: #0d171b;
  border-radius: 12px;
  padding: 12px;
}

.pandora-linear-text {
  background: linear-gradient(180deg, #1bbff4 0%, #1beca7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-size: 24px;
}

.pandora-btn-close {
  cursor: pointer;
}

.pandora-modal-text-title {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.pandora-ticket-wrapper {
  border-radius: 12px;
  float: right;
  position: relative;
  width: max-content;
}

.pandora-modal-select-ticket-text-title {
  color: #ffa000 !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "Space Grotesk";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.83px;
}

.pandora-box-amount-input {
  border-radius: 4px;
  background: #0d171b;
  border: "0px";
  align-items: center;
  color:#1beca7;
  margin-top: 12px;
  padding: 8px;
  justify-content: center;
}