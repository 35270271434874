.navbar-container {
  border: 2px solid rgba(255, 255, 255, 0.4);
  z-index: 100;
  margin: auto;
  /* padding: 16px 24px; */
  align-items: center;
  justify-content: space-between;
  background-color: rgba(13, 23, 27, 0.7);
  transition-delay: 0s, 0s, 0s, 0s;
  transition-duration: 0.25s, 0.25s, 0.25s, 0s;
  transition-property: box-shadow, background-color, filter, border;
  transition-timing-function: linear, linear, linear, linear;
  border-radius: 12px;
  backdrop-filter: blur(7.5px);
  /* max-width: 1440px; */
}

.navbar-logo-container {
  align-items: center;
}
.navbar-logo {
  height: 24px;
}

.btn-switch:hover p{
  color: #0D171B;
}