.admin-modal-tabs {
  background: #0d171b;
  display: flex;
  border-radius: 8px;
  margin: 0 auto;
}
.admin-modal-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 12px;
}
.admin-modal-label {
  text-align: center;
  color: #f7f7f8;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.admin-modal-tab-active {
  background: linear-gradient(180deg, #1bbff4 0%, #1beca7 100%);
  border-radius: 8px;
}
.admin-modal-label-active {
  color: #0d171b;
}

.react-calendar{
  background-color: #0d171b !important;
}

.react-calendar__tile--now{
  background-color: gainsboro !important;
  color: #0d171b;
}